<template>
    <v-content>
        <v-breadcrumbs :items="breadcrumbsItem" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-container>
                 <v-row class="float-right">
                     <v-file-input dense label="Import File" outlined v-model="formdata.import"></v-file-input>
                     <v-btn @click.stop="onImport" class="primary float-right mx-4">
                         <v-icon>publish</v-icon>
                     </v-btn>
                     <v-btn @click.stop="onDownload" class="success float-right mx-4">
                         <v-icon>get_app</v-icon>
                         Export
                     </v-btn>
                 </v-row>
             </v-container>-->
            <div slot="table-actions">
                <v-btn @click.stop="addModal" color="primary">{{$t('training_form')}}</v-btn>
                <v-btn dark
                       @click.stop="$router.push({name:'AcademicPendingList',params:{slug:'TrainingsList'}})"
                       color="rgb(157,25,25)">
                    {{$t('table.pending')}}
                </v-btn>
            </div>
            <v-dialog max-width="500px" v-model="dialogDelete">
                <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
            </v-dialog>
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true,mode: 'records',perPageDropdown: [50, 100,200] }"
                            :rows="rows"
                            :totalRows="customerData.totalRecords"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                        <v-icon @click.stop="editItem(props.row)" class="mr-2" small>edit</v-icon>
                        <v-icon @click.stop="deleteItem(props.row)" small>delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog hide-overlay
                      persistent
                      transition="dialog-bottom-transition"
                      max-width="600"
                      v-model="snackbar.formModal">
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="customerData.updateItem"
             ></component>-->
                    <TrainingForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"></TrainingForm>
                    <TrainingEditForm :editItemIndex="customerData.editItemIndex"
                                      @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"></TrainingEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar :timeout="snackbar.timeout"
                        color="success"
                        top
                        v-model="snackbar.snackbar">
                {{ callbackResponse.message }}
                <v-btn @click="snackbar.snackbar = false"
                       dark
                       text>
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import TrainingForm from "./TrainingForm";
    import TrainingEditForm from "./TrainingEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "TrainingList",
        components: {
            'TrainingForm': TrainingForm,
            'TrainingEditForm': TrainingEditForm,
            deleteListData
        },
        methods: {
            fullName(rowObj) {
                return rowObj.firstName + '   ' + rowObj.lastName
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("Adventure/Import", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            async onDownload() {
                await axios({
                    url: 'Adventure/Download',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportAdventureFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Type: 'training'
                };
                axios.post('Academic/GetStudentListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem({studentID}) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = studentID
            },
            deleteItem({studentID}) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Training/DeleteStudentDetailAsync/" + studentID;
            },
        },
        mounted() {
            this.loadItems()
        },
        computed: {
            breadcrumbsItem() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: 'AcademicDashboard',
                        exact: true

                    },
                    {
                        text: this.$t('training_information'),
                        disabled: true,
                    }
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'name',
                        field: "name"
                    },
                    /* {
                         label: "faculty",
                         field: "Faculty"
                     },*/
                    {
                        label: 'address',
                        field: 'address',
                    },
                    {
                        label: 'number',
                        field: 'mobileNumber',
                    },
                    {
                        label: 'email',
                        field: 'email',
                    },
                    {
                        label: 'amount',
                        field: 'amount',
                    },
                    {
                        label: 'total_amount',
                        field: 'totalAmount',
                    },
                    /* {
                         label: 'Total amount',
                         field: 'totalAmount',
                     },
                     {
                         label: 'Passed Year',
                         field: 'passedYear',
                     },*/
                    {
                        label: 'status',
                        field: 'passedStatus',
                    },
                    {
                        label: 'enrolled_year',
                        field: 'enrollYear',
                    },
                    {
                        label: 'passed_failed_left_year',
                        field: 'passedYear',
                    },
                    { label: 'actions', field: "actions" }
                ],
                rows: [],
                dialogDelete: false,
                deleteFormData: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "studentID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dynamicComponent: {
                        current: 'TrainingForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
